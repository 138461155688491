import { gql } from "@apollo/client/core";

const BILLING_PLAN_FIELDS = `
    available
    baseAmount
    billedEvery
    cycle
    displayMonthlyAmount
    displayName
    extendedDisplayName
    displaySavings
    planName
    discounted
    durationInSeconds
    membershipType
    features
    addon
`;

const BILLING_CHOICE_FIELDS = `
    donationAmount
    plan {
        ${BILLING_PLAN_FIELDS}
    }
`;

const DISCOUNT_FIELDS = `
    amountOff
    percentOff
    duration
    endDate
`;

const USER_PROFILE_FIELDS_FULL = `
    id
    bio
    location
    fullName
    nickName
    picture
    pronouns
    isComplete
    hasSeenCalendarPage
    acceptedCommitments
    completedOnboardingItems
    acceptedLoungeGuidelines
    ageRange
    ethnicity
    sexualOrientation
    gender
    numPositiveSurveyInteractions
    lastDateOfDonation
`;

const USER_PROFILE_FIELDS = `
    id
    bio
    location
    fullName
    nickName
    picture
    pronouns
`;

export const USER_FIELDS = `
    id
    email
    profile {
        ${USER_PROFILE_FIELDS_FULL}
    }
    features
    roles
    timeZone
    teacher {
        id
    }
`;

const PRESENTATION_DATA_FIELDS = `
    description
    quotes
    subtitle
    video
    whoIsThisFor
`;

const TEACHER_FIELDS_SHORT = `
    id
    background
    location
    loveWorkingWith
    name
    picture
    pronouns
    specialties
    training
    website
    yearsMeditating
    yearsTeaching
    shortBio
    previewVideoLink {
        name
        url
    }
    links {
        name
        url
    }
    certifications {
        name
        institution
        yearOfCompletion
    }
`;

const TEACHER_FIELDS = `
    ${TEACHER_FIELDS_SHORT}
    userProfile {
        id
        nickName
        fullName
        location
    }
`;

const SANGHA_MEMBERSHIP_FIELDS = `
    id
    active
    endDate
    startDate
    status
    inviteExpirationDate
    isScheduledToLeave
    isDropIn
    sangha {
        id
        type
        duration
        name
        published
        cycle
        timeZone
        firstSessionDate
        nextSession {
            id
            time
            endTime
        }
        teacher {
            ${TEACHER_FIELDS}
        }
    }
`;

const MEMBERSHIP_FIELDS = `
    id
    active
    billingChoice {
        ${BILLING_CHOICE_FIELDS}
    }
    scheduledChange {
        billingChoice {
            ${BILLING_CHOICE_FIELDS}
        }
        changeDate
    }
    billingPlanOptions {
        ${BILLING_PLAN_FIELDS}
    }
    slidingScaleBillingPlanOptions {
        ${BILLING_PLAN_FIELDS}
    }
    sanghaMemberships {
        ${SANGHA_MEMBERSHIP_FIELDS}
    }
    renewalDate
    startDate
    status
    willRenew
    email
    freeTrialEndDate
    cohort
    stripeSubscriptionId
    stripeCustomerId
    discount {
        ${DISCOUNT_FIELDS}
    }
`;

const INTEREST_GROUP_TOPIC_FIELDS = `
    id
    name
    imageId
`;

const EVENT_FIELDS_PUBLIC = `
    id
    type
    teachers {
        ${TEACHER_FIELDS_SHORT}
    }
    cancelled
    time
    endTime
    notes
    sessionDescription
    title
`;

const EVENT_FIELDS_CORE = `
    ${EVENT_FIELDS_PUBLIC}
    sangha {
        id
        type
        duration
        name
        openSpots
        maxSpots
        availableSpots
        cycle
        timeZone
        teacher {
            ${TEACHER_FIELDS_SHORT}
        }
        eligibleMembershipTypes
        audienceDisplay
        topicData {
            ${INTEREST_GROUP_TOPIC_FIELDS}
        }
        circleSpaceId
        presentationData {
            ${PRESENTATION_DATA_FIELDS}
        }
    }
    zoomInfo {
        joinLink
        startLink
    }
    isPromo
    sanghaMembership {
      id
      isDropIn
    }
    substituteRequested
    hasSubstituteTeacher
    joinLink
`;

const EVENT_FIELDS = `
    ${EVENT_FIELDS_CORE}
    rsvpUsers {
        ${USER_PROFILE_FIELDS}
    }
`;

const SANGHA_CORE_FIELDS = `
    id
    type
    duration
    openSpots
    availableSpots
    audienceDisplay
    eligibleMembershipTypes
    name
    published
    hasAvailability
    teacher {
        ${TEACHER_FIELDS}
    }
    additionalTeachers {
        ${TEACHER_FIELDS}
    }
    presentationData {
        ${PRESENTATION_DATA_FIELDS}
    }
    topicData {
        ${INTEREST_GROUP_TOPIC_FIELDS}
    }
    circleSpaceId
    minimumCommitment
    maxSpots
`;

const SANGHA_PUBLIC_FIELDS = `
    ${SANGHA_CORE_FIELDS}
    cycle
    timeZone
    firstSessionDate
    nextSession {
        id
        time
        endTime
        cancelled
    }
`;

const SANGHA_FIELDS = `
    ${SANGHA_CORE_FIELDS}
    timeZone
    cycle
    firstSessionDate
    nextSession {
        id
        time
        endTime
        cancelled
        zoomInfo {
            joinLink
            startLink
        }
    }
    highlightMemberDisplay
    upcomingSessionForInvitation
    memberships {
        user {
            profile {
                ${USER_PROFILE_FIELDS}
            }
        }
    }
`;

export const GRAPHQL_MUTATION_SAVE_PROFILE = gql`
    mutation SaveUserProfile($id: ID!, $userProfile: UserProfileInput!) {
        saveUserProfile(id: $id, userProfile: $userProfile) {
            ${USER_PROFILE_FIELDS_FULL}
        }
    }
`;

export const GRAPHQL_MUTATION_ACCEPT_COMMITMENTS = gql`
    mutation AcceptCommunityCommitments($id: ID!) {
        acceptCommunityCommitments(id: $id) {
            ${USER_PROFILE_FIELDS_FULL}
        }
    }
`;

export const GRAPHQL_MUTATION_CANCEL_MEMBERSHIP = gql`
    mutation CancelMembership($membershipId: ID!, $cancelDate: DateTime!) {
        cancelMembership(membershipId: $membershipId, cancelDate: $cancelDate)
    }
`;

export const GRAPHQL_MUTATION_SET_POSITIVE_SURVEY_INTERACTION = gql`
    mutation AddPositiveSurveyInteraction {
        addPositiveSurveyInteraction {
            ${USER_PROFILE_FIELDS_FULL}
        }
    }
`;

export const GRAPHQL_MUTATION_SET_NEGATIVE_SURVEY_INTERACTION = gql`
    mutation AddNegativeSurveyInteraction {
        addNegativeSurveyInteraction {
            ${USER_PROFILE_FIELDS_FULL}
        }
    }
`;

export const GRAPHQL_MUTATION_SET_LAST_DONATION_DATE = gql`
    mutation UpdateLastDateOfDonation {
        updateLastDateOfDonation {
            ${USER_PROFILE_FIELDS_FULL}
        }
    }
`;

export const GRAPHQL_MUTATION_SET_CANCELLATION_RESPONSE = gql`
    mutation SetCancellationResponse($cancellationResponse: CancellationResponseInput) {
        postCancellationResponse(cancellationResponse: $cancellationResponse) {
            reasonsForLeaving
            elaborateOnReasons
            doneDifferently
            anythingElse
        }
    }
`;

export const GRAPHQL_QUERY_NEXT_INVOICE = gql`
    query NextStripeInvoice($membershipId: ID!) {
        nextStripeInvoice(membershipId: $membershipId) {
            amountDue
            invoiceDate
        }
    }
`;

export const GRAPHQL_QUERY_STRIPE_CUSTOMER_BALANCE = gql`
    query StripeCustomerBalance($stripeCustomerId: ID!) {
        stripeCustomerBalance(stripeCustomerId: $stripeCustomerId)
    }
`;

export const GRAPHQL_QUERY_CURRENT_TIME = gql`
    query CurrentTime {
        currentTime
    }
`;

export const GRAPHQL_QUERY_USER = gql`
    query Me {
        me {
            ${USER_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_PENDING_SANGHA_MEMBERSHIPS = gql`
    query PendingSanghaMemberships {
        pendingSanghaMemberships {
            ${SANGHA_MEMBERSHIP_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_CREATE_ACCOUNT = gql`
    mutation Signup(
        $email: String!
        $password: String!
        $acceptedTermsOfService: DateTime!
        $userProfile: UserProfileInput
        $referredByPartnerId: String
    ) {
        signup(
            email: $email
            password: $password
            acceptedTermsOfService: $acceptedTermsOfService
            userProfile: $userProfile
            referredByPartnerId: $referredByPartnerId
        ) {
            authToken
            refreshToken
        }
    }
`;

export const GRAPHQL_MUTATION_REFRESH_AUTH_TOKEN = gql`
    mutation RefreshAuthToken($refreshToken: String!) {
        refreshAuthToken(refreshToken: $refreshToken) {
            authToken
            refreshToken
        }
    }
`;

export const GRAPHQL_MUTATION_LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            authToken
            refreshToken
        }
    }
`;

export const GRAPHQL_MUTATION_AUTHORIZE_OAUTH = gql`
    mutation AuthorizeOAuthClient(
        $responseType: String!
        $clientId: String!
        $redirectUri: String!
        $scope: String!
        $state: String
    ) {
        authorizeOAuthClient(
            responseType: $responseType
            clientId: $clientId
            redirectUri: $redirectUri
            scope: $scope
            state: $state
        )
    }
`;

export const GRAPHQL_MUTATION_REQUEST_RESET_PASSWORD = gql`
    mutation RequestPasswordReset($email: String!) {
        requestPasswordReset(email: $email)
    }
`;

export const GRAPHQL_MUTATION_POST_MESSAGE = gql`
    mutation PostMessage($sanghaId: ID!, $content: String!) {
        postMessage(sanghaId: $sanghaId, content: $content) {
            id
            author {
                id
                name
                picture
            }
            content
            date
            type
            messageThread {
                id
            }
        }
    }
`;

export const GRAPHQL_MUTATION_DELETE_MESSAGE = gql`
    mutation DeleteMessage($thread: ID!, $message: ID!) {
        deleteMessage(thread: $thread, message: $message)
    }
`;

export const GRAPHQL_MUTATION_POST_SANGHA_SESSION_INDIVIDUAL_ATTENDANCE = gql`
    mutation PostSanghaSessionIndividualAttendance($sanghaId: ID!, $sanghaSessionId: ID!) {
        postSanghaSessionIndividualAttendance(
            sanghaId: $sanghaId
            sanghaSessionId: $sanghaSessionId
        ) {
            id
        }
    }
`;

export const GRAPHQL_MUTATION_POST_SESSION_ATTENDANCE = gql`
    mutation PostSessionAttendance($sessionId: ID!) {
        postSessionAttendance(sessionId: $sessionId) {
            id
        }
    }
`;

export const GRAPHQL_MUTATION_POST_SESSION_RSVP = gql`
    mutation postSessionRsvp($sessionId: ID!, $sendEmail: Boolean) {
        postSessionRsvp(sessionId: $sessionId, sendEmail: $sendEmail) {
            ${EVENT_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_POST_CANCEL_SESSION_RSVP = gql`
    mutation postCancelSessionRsvp($sessionId: ID!) {
        postCancelSessionRsvp(sessionId: $sessionId) {
            ${EVENT_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_CANCEL_INTEREST_GROUP_MEMBERSHIP = gql`
    mutation CancelGroupMembership($sanghaMembershipId: ID!) {
        cancelGroupMembership(sanghaMembershipId: $sanghaMembershipId)
    }
`;

export const GRAPHQL_QUERY_SIGN_UP_BILLING_PLAN_OPTIONS = gql`
    query SignupBillingPlanOptions {
        signupBillingPlanOptions {
            ${BILLING_PLAN_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_BILLING_PLANS_FROM_NAME = gql`
    query BillingPlanFromName($planNames: [String!]!) {
        billingPlansFromName(planNames: $planNames) {
            ${BILLING_PLAN_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_CREATE_MEMBERSHIP_CHECKOUT_SESSION = gql`
    mutation CreateMembershipCheckoutSession(
        $billingChoice: BillingChoiceInput!
        $referralCode: String
        $utmParameters: UTMParametersInput
        $affiliateUtmParameters: UTMParametersInput
        $scholarshipApplicationId: String
        $referredByPartnerId: String
        $isFreeTrial: Boolean
        $selectedGroupId: String
        $isDropIn: Boolean
        $cohort: Cohort
        $trialDays: Int
    ) {
        createMembershipCheckoutSession(
            billingChoice: $billingChoice
            referralCode: $referralCode
            utmParameters: $utmParameters
            affiliateUtmParameters: $affiliateUtmParameters
            scholarshipApplicationId: $scholarshipApplicationId
            referredByPartnerId: $referredByPartnerId
            isFreeTrial: $isFreeTrial
            selectedGroupId: $selectedGroupId
            isDropIn: $isDropIn
            cohort: $cohort
            trialDays: $trialDays
        ) {
            sessionId
            clientSecret
        }
    }
`;

export const GRAPHQL_QUERY_MY_SANGHAS = gql`
    query MySanghas {
        mySanghas {
            id
            name
            type
        }
    }
`;

export const GRAPHQL_QUERY_UPCOMING_EVENTS = gql`
    query UpcomingEvents(
        $includeTypes: [SessionType]
        $startsWithinMinutes: Int
        $startsAfterMinutes: Int
        $limit: Int
        $startingMonday: Boolean
    ) {
        upcomingCommunityEvents(
            includeTypes: $includeTypes
            startsWithinMinutes: $startsWithinMinutes
            startsAfterMinutes: $startsAfterMinutes
            limit: $limit
            startingMonday: $startingMonday
        ) {
            ${EVENT_FIELDS_CORE}
        }
    }
`;

export const GRAPHQL_QUERY_MY_SCHEDULE = gql`
    query MySchedule($limit: Int) {
        mySchedule(limit: $limit) {
            ${EVENT_FIELDS_CORE}
        }
    }
`;

export const GRAPHQL_QUERY_UPCOMING_EVENTS_PUBLIC = gql`
    query UpcomingEventsPublic {
        upcomingEventsPublic {
            ${EVENT_FIELDS_PUBLIC}
        }
    }
`;

export const GRAPHQL_QUERY_EVENT_RSVP_USERS = gql`
    query Event($id: ID!) {
        event(id: $id) {
            rsvpUsers {
                ${USER_PROFILE_FIELDS}
            }
        }
    }
`;

export const GRAPHQL_QUERY_EVENT = gql`
    query Event($id: ID!) {
        event(id: $id) {
            ${EVENT_FIELDS_CORE}
        }
    }
`;

export const GRAPHQL_QUERY_UPCOMING_RSVPED_SESSIONS_FOR_USER = gql`
    query UpcomingRsvpedSessionsForUser {
        upcomingRsvpedSessionsForUser {
            id
        }
    }
`;

export const GRAPHQL_QUERY_AVAILABLE_GROUPS = gql`
    query AvailableImmersionGroups($limit: Int) {
        availableImmersionGroups(limit: $limit) {
            ${SANGHA_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_PUBLICALLY_AVAILABLE_INTEREST_GROUPS = gql`
    query PublicallyAvailableInterestGroups {
        availableInterestGroups {
            ${SANGHA_PUBLIC_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_AVAILABLE_INTEREST_GROUPS = gql`
    query AvailableInterestGroups {
        allUpcomingAvailableInterestGroupSessions {
            ${EVENT_FIELDS_CORE}
        }
    }
`;

export const GRAPHQL_QUERY_POD_ASSIGNMENT = gql`
    query PodAssignment($sanghaId: ID!) {
        podAssignment(sanghaId: $sanghaId)
    }
`;

export const GRAPHQL_QUERY_SANGHA_DETAILS = gql`
    query SanghaDetails($id: ID!) {
        sangha(id: $id) {
            ${SANGHA_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_MEMBERSHIPS = gql`
    query MyMemberships {
        myMemberships {
            ${MEMBERSHIP_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_PAY_FOR_ONE_TIME_DONATION = gql`
    mutation PayForOneTimeDonation($amountInDollars: Int!) {
        payForOneTimeDonation(amountInDollars: $amountInDollars)
    }
`;

export const GRAPHQL_MUTATION_INVITE_MEMBER_TO_CIRCLE = gql`
    mutation InviteMemberToCircle($spaceIds: [ID!], $spaceGroupIds: [ID!]) {
        inviteMemberToCircle(spaceIds: $spaceIds, spaceGroupIds: $spaceGroupIds)
    }
`;

const MESSAGE_THREAD_FIELDS = `
    id
    sanghaSession {
        id
        time
    }
    messages {
        id
        author {
            id
            nickName
            fullName
            picture
        }
        content
        type
        date
    }
`;

export const GRAPHQL_QUERY_MESSAGE_THREAD = gql`
    query MessageThread($sanghaId: ID!) {
        messageThread(sanghaId: $sanghaId) {
           ${MESSAGE_THREAD_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_OLD_MESSAGE_THREAD = gql`
    query MessageThread($nextThreadId: ID!) {
        messageThread(nextThreadId: $nextThreadId) {
            ${MESSAGE_THREAD_FIELDS}
        }
    }
`;

const NOTIFICATION_PREFERENCE_FIELDS = `
    id
    type
    mode
    enabled
    frequency
`;

export const GRAPHQL_QUERY_NOTIFICATION_PREFERENCES = gql`
    query NotificationPreferences {
        notificationPreferences {
            ${NOTIFICATION_PREFERENCE_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_UPDATE_NOTIFICATION_PREFERENCE = gql`
    mutation UpdateNotificationPreference($id: ID!, $enabled: Boolean!) {
        updateNotificationPreference(id: $id, enabled: $enabled) {
            ${NOTIFICATION_PREFERENCE_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_UPDATE_MESSAGE_NOTIFICATION_EMAIL_FREQUENCY = gql`
    mutation UpdateMessageNotificationEmailFrequency($frequency: NotificationFrequency) {
        updateMessageNotificationEmailFrequency(frequency: $frequency) {
            ${NOTIFICATION_PREFERENCE_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_TEACHERS = gql`
    query Teachers {
        teachers {
            ${TEACHER_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_TEACHER = gql`
    query Teacher($id: ID, $userId: ID) {
        teacher(id: $id, userId: $userId) {
            ${TEACHER_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_ADD_ON_MENTORSHIP_GROUP = gql`
    mutation AddOnMentorshipGroup($sanghaId: ID!) {
        joinGroup(sanghaId: $sanghaId) {
            ${SANGHA_MEMBERSHIP_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_ADD_INTEREST_GROUP = gql`
    mutation AddInterestGroup($sanghaId: ID!, $isDropIn: Boolean) {
        joinGroup(sanghaId: $sanghaId, isDropIn: $isDropIn) {
            ${SANGHA_MEMBERSHIP_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_UPDATE_GROUP_COMMITMENT = gql`
    mutation UpdateGroupCommitment($sanghaId: ID!, $isDropIn: Boolean!) {
      updateGroupCommitment(sanghaId: $sanghaId, isDropIn: $isDropIn) {
            ${SANGHA_MEMBERSHIP_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_MEMBERSHIP_BILLING = gql`
    mutation UpdateMembershipBilling(
        $membershipId: ID!
        $billingChoice: BillingChoiceInput!
        $changeDate: DateTime
        $removeDiscount: Boolean
    ) {
        updateMembershipBilling(
            membershipId: $membershipId
            billingChoice: $billingChoice
            changeDate: $changeDate
            removeDiscount: $removeDiscount
        ) {
            ${MEMBERSHIP_FIELDS}
        }
    }
`;

export const GRAPHQL_MUTATION_MEMBERSHIP_PAYMENT_METHOD = gql`
    mutation UpdateMembershipBillingPaymentMethod(
        $membershipId: ID!
        $successUrl: String!
        $failedUrl: String!
        $developmentMode: Boolean
    ) {
        updateMembershipBillingPaymentMethod(
            membershipId: $membershipId
            successUrl: $successUrl
            failedUrl: $failedUrl
            developmentMode: $developmentMode
        )
    }
`;

export const GRAPHQL_QUERY_SANGHA = gql`
    query Sangha($sanghaId: ID!) {
        sangha(id: $sanghaId) {
            ${SANGHA_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_INTEREST_GROUP = gql`
    query InterestGroup($groupId: ID!) {
        interestGroup(id: $groupId) {
            ${SANGHA_PUBLIC_FIELDS}
            memberships {
                id
            }
        }
    }
`;

export const GRAPHQL_MUTATION_SEND_SUPPORT_TICKET = gql`
    mutation SendSupportTicket(
        $name: String!
        $email: String!
        $message: String!
        $topic: String!
    ) {
        sendSupportTicket(name: $name, email: $email, message: $message, topic: $topic)
    }
`;

export const GRAPHQL_MUTATION_SUBMIT_TEACHER_POST_SESSION_DATA = gql`
    mutation SubmitTeacherPostSessionData($sanghaId: ID!, $data: TeacherPostSessionInput!) {
        submitTeacherPostSessionData(sanghaId: $sanghaId, data: $data) {
            id
        }
    }
`;

export const GRAPHQL_QUERY_CURRENT_DEFAULT_REFLECTION_QUESTION = gql`
    query CurrentDefaultReflectionQuestion {
        currentDefaultReflectionQuestion
    }
`;

export const GRAPHQL_MUTATION_REQUEST_SUBSTITUTE = gql`
    mutation RequestSubstituteForSession($sessionId: ID!, $substituteTeacherId: ID) {
        requestSubstituteForSession(sessionId: $sessionId, substituteTeacherId: $substituteTeacherId) {
            ${EVENT_FIELDS_CORE}
        }
    }
`;

export const GRAPHQL_QUERY_SUB_REQUESTED_SESSIONS = gql`
    query SessionsWithSubRequestedByTeacherId($teacherId: ID!) {
        sessionsWithSubRequestedByTeacherId(teacherId: $teacherId) {
            ${EVENT_FIELDS_CORE}
        }
    }
`;

export const GRAPHQL_QUERY_INTEREST_GROUP_TOPICS = gql`
    query InterestGroupTopics {
        interestGroupTopics {
            id
            name
            imageId
        }
    }
`;

export const GRAPHQL_QUERY_TEST_SESSION = gql`
    query GetTestSession {
        testSession {
            zoomInfo {
                joinLink
                startLink
            }
        }
    }
`;

const COURSE_FIELDS = `
id
name
description
price
priceForMembers
url
landingPageUrl
landingPageUrlForMembers
completedCourseCount
availability
imageUrl
teachers {
    ${TEACHER_FIELDS}
}
myCourseMembership {
    status
}
createdDate
`;

export const GRAPHQL_QUERY_COURSES = gql`
    query courses {
        courses {
            ${COURSE_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_COURSE = gql`
    query course($id: ID!) {
        course(id: $id) {
            ${COURSE_FIELDS}
        }
    }
`;

export const GRAPHQL_QUERY_COURSE_MEMBERSHIP = gql`
    query courseMembership($courseId: ID!) {
        courseMembership(courseId: $courseId) {
            courseId
            status
        }
    }
`;

export const GRAPHQL_MUTATION_OPT_OUT_OF_COURSE = gql`
    mutation optOutOfCourse($courseId: ID!) {
        optOutOfCourse(courseId: $courseId) {
            courseId
            status
        }
    }
`;

export const GRAPHQL_MUTATION_SUB_SESSION = gql`
    mutation confirmSessionSubstitution($sessionId: ID!, $substituteTeacherId: ID!) {
        confirmSessionSubstitution(sessionId: $sessionId, substituteTeacherId: $substituteTeacherId)
    }
`;

export const GRAPHQL_MUTATION_CREATE_COURSE_CHECKOUT_SESSION = gql`
    mutation createCourseCheckoutSession(
        $courseId: ID!
        $utmParameters: UTMParametersInput
        $affiliateUtmParameters: UTMParametersInput
        $installments: Int
    ) {
        createCourseCheckoutSession(
            courseId: $courseId
            utmParameters: $utmParameters
            affiliateUtmParameters: $affiliateUtmParameters
            installments: $installments
        ) {
            sessionId
            clientSecret
        }
    }
`;

export const GRAPHQL_QUERY_IS_USER_ENROLLED_IN_COURSES = gql`
    query isUserEnrolledInCourses {
        isUserEnrolledInCourses
    }
`;

export const GRAPHQL_QUERY_GROUPS_BY_TEACHER_ID = gql`
    query groupsByTeacherId($teacherId: ID!, $sanghaType: SanghaType!) {
        groupsByTeacherId(teacherId: $teacherId, sanghaType: $sanghaType) {
            ${SANGHA_PUBLIC_FIELDS}
        }
    }
`;

export const GRAPHLQ_QUERY_HAS_USER_ATTENDED_SESSION_TYPE = gql`
    query hasUserAttendedSessionType($sessionType: SessionType!) {
        hasUserAttendedSessionType(sessionType: $sessionType)
    }
`;

export const GRAPHQL_MUTATION_MARK_ONBOARDING_ITEM_COMPLETE = gql`
    mutation markOnboardingItemComplete($onboardingItem: OnboardingItemType!) {
        markOnboardingItemComplete(onboardingItem: $onboardingItem)
    }
`;

export const GRAPHQL_MUTATION_ACCEPT_LOUNGE_GUIDELINES = gql`
    mutation acceptLoungeGuidelines {
        acceptLoungeGuidelines
    }
`;

export const GRAPHQL_QUERY_GET_ALL_ACTIVE_SANGHA_TEACHER_IDS = gql`
    query getAllActiveSanghaTeacherIds($sanghaType: SanghaType!) {
        getAllActiveSanghaTeacherIds(sanghaType: $sanghaType)
    }
`;
