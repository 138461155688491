import { useContext } from "react";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LoungeOpenedSuccessMessage } from "./LoungeOpenedSuccessMessage";
import { LoungeWelcomeMessage } from "./LoungeWelcomeMessage";
import { Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import { useQueryParams } from "hooks/useQueryParams";
import { LOUNGE_BASE_URL } from "@app/shared/constants";

export const LoungePage = () => {
    const user = useContext(LoggedInUserProfileContext);
    const query = useQueryParams();

    const loungeUrl = query.id ? `${LOUNGE_BASE_URL}/c/${query.id}/` : `${LOUNGE_BASE_URL}/c/feed/`;

    if (user.profile.acceptedLoungeGuidelines) {
        const loungeTab = window.open(loungeUrl, "_blank");
        if (!loungeTab) {
            window.location.href = loungeUrl;
            return (
                <PageWrapper>
                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                        You will be redirected...
                    </Typography>
                </PageWrapper>
            );
        }
        return <LoungeOpenedSuccessMessage loungeUrl={loungeUrl} />;
    }

    return <LoungeWelcomeMessage loungeUrl={loungeUrl} />;
};
